import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <p className="App-title">We develop software as good as you need</p>
                <p className="App-small">Coming soon!</p>
            </header>
            <footer className="App-footer">
                <p>© 2024 OPEN8 | <a className="App-link" href="mailto:office@open8.co" target="_blank"  rel="noopener noreferrer">office@open8.co</a></p>
            </footer>
        </div>
    );
}

export default App;
